import { useEffect, useState } from "react"
import { Box,
         Grid,
         MenuItem,
         FormControl,
         Select,
         InputLabel,
         TextField,
         Autocomplete
  } from "@mui/material";

import CandidateGrid from "./DataGrid";
import CandidateDetail from "./Candidate";
import { Link } from 'react-router-dom';

export default function Data(props) {
  const [states, setStates] = useState([]);
  const [candidateNames, setCandidateNames] = useState([]);

  const [filterState, setFilterState] = useState("");
  const [filterText, setFilterText] = useState("");
  const [filterDistricts, setFilterDistricts] = useState([]);

  const candidates = props?.candidates;
  const candidate_slug = props?.slug;
  const zip_codes = props?.zip_codes;

  // get unique states from candidate list
  useEffect(() => {
    const states = candidates.map((candidate) => candidate.state_postal);
    const uniqueStates = [...new Set(states)];
    setStates(uniqueStates.sort());

    const uniqueNames = [...new Set(candidates.map((candidate) => candidate.candidate))];

    // look up the state abbreviation for each unique candidate from the candidate object
    for (var i = 0; i < uniqueNames.length; i++) {
      for (var j = 0; j < candidates.length; j++) {
        if (candidates[j].candidate === uniqueNames[i]) {
          uniqueNames[i] = candidates[j];
          break;
        }
      }
    }

    const labels = uniqueNames.map((candidate) => ({label: candidate.candidate,
                                                    state: candidate.state_postal}));
    setCandidateNames(labels);

  }, [candidates]);

  const handleChange = (event) => {
    setFilterState(event.target.value);
  }

  const handleTextChange = (event) => {
    if (event.target.type === "text") {
      setFilterText(event.target.value);
    } else {
      setFilterText(event.target.innerText);
    }
  }

  const handleZipCode = (event) => {
    // check if this zip code is in the dictionary of zip codes
    if (Object.keys(zip_codes).includes(event.target.value)) {

      if (zip_codes[event.target.value][0][0] === "PR"
          || zip_codes[event.target.value][0][0] === "VI"
          || zip_codes[event.target.value][0][0] === "GU"
          || zip_codes[event.target.value][0][0] === "AS"
          || zip_codes[event.target.value][0][0] === "MP"
          || zip_codes[event.target.value][0][0] === "DC") {
        // taxation without representation
        setFilterState("");
        setFilterDistricts([]);
        return;
      }

      // assume only one state
      setFilterState(zip_codes[event.target.value][0][0]);

      // all districts in this zip code
      let districts = [];
      for (var i = 0; i < zip_codes[event.target.value].length; i++) {
        districts.push(zip_codes[event.target.value][i][1]);
      }
      setFilterDistricts( districts );
    } else {
      setFilterDistricts([]);
    }
  }

  const filterCandidateNamesByState = (cs, state) => {
    if (state === undefined || state === "") {
      return cs;
    }
    return cs.filter((candidate) => candidate.state === state);
  }

  const filterCandidates = (cs, filterState, filterText) => {
    if (filterState === "" && filterText === "") {
      return cs;
    }
    return cs
      .filter((candidate) => filterState === "" || candidate.state_postal === filterState)
      .filter((candidate) => filterDistricts.length === 0 || filterDistricts.includes(candidate.cd))
      .filter((candidate) => filterText === undefined || filterText === "" || candidate.candidate.toLowerCase().includes(filterText.toLowerCase()));
  }

  return (
    // display loading screen while candidates are fetched
    <div className='data-panel'>
      <Grid container columns={24} spacing={3}>
      {candidate_slug ? <Grid item xs={24} md={3}>
        <div class='grid-card sticky'>
        <div class="back-button"><Link to={'/explore'}><span class='chevron'>&#8249;</span> Back</Link></div>
        </div>
      </Grid>
      : <Grid item xs={24} md={6} >
        <div class="grid-card sticky">
          <Box p={2}>
            <div class='filter-title'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21 4H14" stroke="#0006A9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 4H3" stroke="#0006A9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21 12H12" stroke="#0006A9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 12H3" stroke="#0006A9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21 20H16" stroke="#0006A9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 20H3" stroke="#0006A9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14 2V6" stroke="#0006A9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 10V14" stroke="#0006A9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 18V22" stroke="#0006A9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span>Filter Data</span>
            </div>
          </Box>
          <Box p={2}>
          <Autocomplete label="Name"
                        freeSolo={true}
                        autoComplete={true}
                        onChange={handleTextChange}
                        onInput={handleTextChange}
                        options={filterCandidateNamesByState(candidateNames, filterState)}
                        renderInput={(params) => <TextField {...params} label="Candidate" />}
                      />
          </Box>
          <Box p={2} width={100}>
          <FormControl size='medium' fullWidth>
            <InputLabel id="state-select-label">State</InputLabel>
            <Select
              labelId="label"
              id="state-select"
              value={filterState}
              label="State"
              onChange={handleChange}
            >
              <MenuItem value="">All</MenuItem>
              {states.map((state) => (
                <MenuItem key={state} value={state}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>
          </Box>

          {/* <Box p={2}>
              <TextField id="zipcode"
                        label="Zip Code"
                        variant="outlined"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleZipCode}/>
          </Box> */}
        </div>
      </Grid>}
      <Grid item xs={24} md={candidate_slug ? 21 : 18}>
        <div class="grid-card table">
          {candidate_slug ? (
            <CandidateDetail candidate={candidate_slug} /> ) :
            ( <CandidateGrid candidates={filterCandidates(candidates, filterState, filterText)} />
            )
          }
        </div>
    </Grid>
    </Grid>
    </div>
  )
};
