
import { Nav } from 'react-bootstrap';
import './App.css';
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import CapitolSVG from './assets/capitolSVG.js';

export default function Header() {
    return (
        <div className="header-style">
          <Navbar bg="dark" variant="dark">
            <div class="title">
              <h2>CampaignView</h2>
            </div>
            <Nav className="mr-auto">
                <NavLink 
                  className={({isActive, isPending}) => isPending ? "pending" : isActive ? "active" : "visited"}
                  to="/">Home
                </NavLink>
                <NavLink 
                  className={({isActive, isPending}) => isPending ? "pending" : isActive ? "active" : "visited"}
                  to="/explore">Explore
                </NavLink>
                <NavLink 
                  className={({isActive, isPending}) => isPending ? "pending" : isActive ? "active" : "visited"}
                  to="/Download">Download
                </NavLink>
                <NavLink 
                  className={({isActive, isPending}) => isPending ? "pending" : isActive ? "active" : "visited"}
                  to="/about">About
                </NavLink>
            </Nav>
          </Navbar>
        </div>
    )
};
