
import React from "react";
import Header from "./Header";
import Footer from "./Footer"
import { NavLink } from "react-router-dom";

function About(props) {
    const persons = [
      {
        name: "Lorem Ipsum",
        bio: "Lorem ipsum odor amet, consectetuer adipiscing elit. Auctor parturient odio elit vulputate urna convallis eu euismod. Placerat dolor tellus natoque metus in torquent a mus. Ipsum hendrerit mauris congue eu, fusce donec odio. Asit imperdiet sollicitudin tellus sollicitudin sit tristique facilisi. Ullamcorper erat venenatis morbi varius ligula nulla ut. Senectus fermentum nam pellentesque malesuada nibh duis magna bibendum.",
        img: "https://i.pinimg.com/originals/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
      },
      {
        name: "Lorem Ipsum",
        bio: "Lorem ipsum odor amet, consectetuer adipiscing elit. Auctor parturient odio elit vulputate urna convallis eu euismod. Placerat dolor tellus natoque metus in torquent a mus. Ipsum hendrerit mauris congue eu, fusce donec odio. Asit imperdiet sollicitudin tellus sollicitudin sit tristique facilisi. Ullamcorper erat venenatis morbi varius ligula nulla ut. Senectus fermentum nam pellentesque malesuada nibh duis magna bibendum.",
        img: "https://i.pinimg.com/originals/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
      },
      {
        name: "Lorem Ipsum",
        bio: "Lorem ipsum odor amet, consectetuer adipiscing elit. Auctor parturient odio elit vulputate urna convallis eu euismod. Placerat dolor tellus natoque metus in torquent a mus. Ipsum hendrerit mauris congue eu, fusce donec odio. Asit imperdiet sollicitudin tellus sollicitudin sit tristique facilisi. Ullamcorper erat venenatis morbi varius ligula nulla ut. Senectus fermentum nam pellentesque malesuada nibh duis magna bibendum.",
        img: "https://i.pinimg.com/originals/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
      }
    ]
    return (
      <div id="page-wrapper">
        <Header />
        <div id="home" class='content'>
          <div class="headline">
            <h1><bold>Understand</bold> political campaigns with open, public <span class="blue-text">research</span> datasets</h1>
            <div class="grid-card">
              <p>Dive into the records, learn about candidates, and explore their policies. Evidence to help you get informed, supported by latest political science research.</p>
              <div class='home-buttons'>
                <NavLink to="/explore">
                  <div class='button main'>Explore Data</div>
                </NavLink>
                <NavLink to="/about">
                  <div class='button'>Learn More</div>
                </NavLink>
              </div>
            </div>
          </div>

          <div class='grid-card home-icons'>
            <div class='icon-container'>
              <div class='svg-container'>
                <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
                  <circle cx="100" cy="100" r="100" fill="#56576F"/>
                  <circle cx="99.6929" cy="73.3042" r="33.3042" fill="#FBFEFF"/>
                  <path d="M141.579 120.365C159.797 141.479 159.774 160.549 99.4627 160.549C39.1512 160.549 42.0135 142.58 57.3469 120.365C72.6803 98.1492 81.058 118.135 99.4627 118.135C117.867 118.135 123.36 99.2505 141.579 120.365Z" fill="#FBFEFF"/>
                </svg>
              </div>
              <p class='icon-title'>Candidate Bios</p>
              <p class='icon-body'>Find detailed information about major political candidates</p>
            </div>
            <div class='icon-container'>
              <div class='svg-container'>
                <svg width="199" height="199" viewBox="0 0 199 199" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="199" height="199" rx="16" fill="#56576F"/>
                  <path d="M33 138.167L46.1667 151.333L72.5 125" stroke="#FBFEFF" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M100 55H171" stroke="#FBFEFF" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M100 99H171" stroke="#FBFEFF" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M100 142H171" stroke="#FBFEFF" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                  <rect x="25" y="46" width="48" height="48" rx="15" stroke="#B3B3B3" stroke-width="6"/>
                  <path d="M38 59L59.2132 80.2132" stroke="#B3B3B3" stroke-width="6" stroke-linecap="round"/>
                  <path d="M37.7868 80.2134L59 59.0002" stroke="#B3B3B3" stroke-width="6" stroke-linecap="round"/>
                </svg>
              </div>
              <p class='icon-title'>Campaign Platforms</p>
              <p class='icon-body'>See where politicians are conducting their campaigns</p>
            </div>
            <div class='icon-container'>
              <div class='svg-container'>
                <svg width="224" height="253" viewBox="0 0 224 253" fill="none" xmlns="http://www.w3.org/2000/svg" class='negative-height'>
                  <path d="M19.9523 97.433C21.192 94.4465 24.1076 92.5 27.3411 92.5H194.796C197.96 92.5 200.827 94.3648 202.109 97.2573L218.515 134.257C220.86 139.547 216.988 145.5 211.202 145.5H11.9826C6.27856 145.5 2.40706 139.701 4.59386 134.433L19.9523 97.433Z" fill="#56576F"/>
                  <path d="M40.3652 114.465C40.9854 112.973 42.4428 112 44.0589 112H177.941C179.557 112 181.015 112.973 181.635 114.465L188.7 131.465C189.794 134.099 187.859 137 185.006 137H36.994C34.1413 137 32.2055 134.099 33.3003 131.465L40.3652 114.465Z" fill="white"/>
                  <path d="M57 89H166V114H57V89Z" fill="white"/>
                  <path d="M92.25 79.0002L105.417 92.1668L131.75 65.8335" stroke="#56576F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M65.9167 46.0832C65.9167 38.8415 71.8417 32.9165 79.0834 32.9165H144.917C148.409 32.9165 151.758 34.3037 154.227 36.7729C156.696 39.2422 158.083 42.5912 158.083 46.0832V125.083H65.9167V46.0832Z" stroke="#56576F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M177.833 125.083H46.1667" stroke="#56576F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                  <rect y="152" width="223" height="96" rx="8" fill="#56576F"/>
                  <path d="M59.4036 221H47.7825L38.1028 178.781H50.0794L52.1575 190.84C52.9414 195.452 53.5703 200.611 54.0442 206.316C54.2447 203.783 54.4908 201.413 54.7825 199.207C55.0742 197.001 55.4205 194.85 55.8216 192.754L58.5286 178.781H70.9153L59.4036 221ZM113.275 200.027C113.275 206.462 111.808 211.694 108.873 215.723C105.956 219.733 102.155 221.738 97.4705 221.738C93.3142 221.738 89.7504 220.198 86.7791 217.117C83.0421 213.216 81.1736 207.365 81.1736 199.562C81.1736 192.089 83.1241 186.374 87.0252 182.418C89.9236 179.483 93.3598 178.016 97.3338 178.016C102.201 178.016 106.075 180.003 108.955 183.977C111.835 187.951 113.275 193.301 113.275 200.027ZM101.435 200.109C101.435 192.471 100.086 188.652 97.3884 188.652C96.0395 188.652 94.964 189.655 94.1619 191.66C93.378 193.52 92.9861 196.254 92.9861 199.863C92.9861 203.418 93.3507 206.18 94.0798 208.148C94.8272 210.099 95.8572 211.074 97.1697 211.074C98.5187 211.074 99.5577 210.108 100.287 208.176C101.053 206.243 101.435 203.555 101.435 200.109ZM142.756 221H131.354V188.898H125.065V178.781H149.291V188.898H142.756V221ZM161.764 221V178.781H182.956V188.707H173.167V194.859H181.944V204.402H173.167V210.855H182.956V221H161.764Z" fill="white"/>
                </svg>
              </div>
              <p class='icon-title'>Election Metadata</p>
              <p class='icon-body'>Learn more about meta information for election processes and results</p>
            </div>
          </div>

          <div class='grid-card citation-block'>
            <h3>Cite Us</h3>
            <div class='grid-card'>
              <p>[citation data]</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default About;