import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import About from './About';
import Home from './Home'
import ErrorPage from './ErrorPage';
import Download from './Download';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />
  },
  {
    path: "/explore",
    element: <App />,
    errorElement: <ErrorPage />
  },
  {
    path: "about/",
    element: <About />,
    errorElement: <ErrorPage />
  },
  {
    path: "candidate/:candidate_slug",
    element: <App />,
    errorElement: <ErrorPage />
  },
  {
    path: "download",
    element: <Download />,
    errorElement: <ErrorPage />
  },
  {
    notFoundElement: <ErrorPage />
  },
  ],
  );

root.render(
  <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
