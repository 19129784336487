
import React from "react";
import Header from "./Header";
import Footer from "./Footer"

function Download(props) {
    return (
      <div id="page-wrapper">
        <Header />
        <div id="download" class='content'>
          <div class="grid-card">
            <h2>Download</h2>
            <p>Download the data from this project from the following links:</p>
            <ul>
              <li><a href={process.env.PUBLIC_URL + '/candidate_issues.csv'} >Candidate Issues</a></li>
              <li><a href={process.env.PUBLIC_URL + '/candidate_biographies.csv'} >Candidate Biographies</a></li>
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default Download;